import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Using TypeScript with higher order components or HOCs in React." title="Higher Order Components (HOCs)" keywords={['higher-order components', 'higher order components', 'hocs']} mdxType="SEO" />
    <h1 {...{
      "id": "higher-order-components-hocs"
    }}>{`Higher Order Components (HOCs)`}</h1>
    <p>{`Please note that this section uses the `}<inlineCode parentName="p">{`utility-types`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://github.com/piotrwitek/utility-types"
      }}>{`package`}</a>{` since, as of May 2019, the helper functions were unavailable in TypeScript.`}</p>
    <h2 {...{
      "id": "injected-props"
    }}>{`Injected Props`}</h2>
    <p>{`HOCs often inject props into wrapped components. In the below example, `}<inlineCode parentName="p">{`T`}</inlineCode>{` is defined as the `}<inlineCode parentName="p">{`WrappedComponent`}</inlineCode>{` props together with the`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ComponentType } from 'react';
import { Diff } from 'utility-types';

export interface WithWoofStringProps {
  woof: string;
}

export default function withWoofString<T extends WithWoofStringProps>(
  WrappedComponent: ComponentType<T>
) {
  const woof = 'woof';

  // note below that Diff removes the props from T that exist in WithWoofStringProps
  // this leaves us with the props of the originally passed in component (without the injected props)
  const hocComponent = (props: Diff<T, WithWoofStringProps>) => (
    <WrappedComponent {...(props as T)} woof={woof} />
  );
  return hocComponent;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      